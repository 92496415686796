import React from "react";

const Header = ({ activeStep }) => {
  return (
    <div className="flex items-center justify-between mt-8 max-[330px]:flex-col max-[330px]:justify-center max-[330px]:items-start max-[330px]:gap-3">
      {/* 1st step */}
      <div className="flex items-center gap-3">
        <p
          className={`px-4 py-[0.4rem] text-white text-[1.1rem] font-bold w-fit rounded-md ${
            activeStep === 1 ? "bg-[#6163f1]" : "bg-black"
          } max-[443px]:px-3 max-[443px]:py-[0.2rem]`}
        >
          1
        </p>
        <p className="text-[1.4rem] text-black font-bold max-[443px]:text-[1.2rem]">
          Connect
        </p>
      </div>
      {/* 2nd step */}
      <div className="flex items-center gap-3">
        <p
          className={`px-4 py-[0.4rem] text-white text-[1.1rem] font-bold w-fit rounded-md ${
            activeStep === 2 ? "bg-[#6163f1]" : "bg-black"
          } max-[443px]:px-3 max-[443px]:py-[0.2rem]`}
        >
          2
        </p>
        <p className="text-[1.4rem] text-black font-bold max-[443px]:text-[1.2rem]">
          Apply
        </p>
      </div>
      {/* 3rd step */}
      <div className="flex items-center gap-3">
        <p
          className={`px-4 py-[0.4rem] text-white text-[1.1rem] font-bold w-fit rounded-md ${
            activeStep === 3 ? "bg-[#6163f1]" : "bg-black"
          } max-[443px]:px-3 max-[443px]:py-[0.2rem]`}
        >
          3
        </p>
        <p className="text-[1.4rem] text-black font-bold  max-[443px]:text-[1.2rem]">
          Contest Entry
        </p>
      </div>
    </div>
  );
};

export default Header;
