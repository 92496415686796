import backButton from "../Assets/Images/backButton.jpg";

export default function JuleTokenRedirect() {
  return (
    <div className="w-full flex items-center flex-col gap-3 mt-6">
      <a
        className="flex justify-center items-center gap-2 cursor-pointer"
        href="https://juletoken.com"
      >
        <img src={backButton} alt="Back Button" className="w-8 h-8" />
        <span className="text-lg font-bold">BACK TO JULETOKEN.COM</span>
      </a>
      <hr className="border-2 border-black border-solid w-full" />
    </div>
  );
}
