import { Navigate, createBrowserRouter } from "react-router-dom";
import PrimaryLayout from "./layout/PrimaryLayout";
import HomePage from "./Pages/HomePage";
import SignUp from "./Pages/SignUp";
import Form1 from "./Pages/Form1";
import Form2 from "./Pages/Form2";

const router = () => {
  return createBrowserRouter([
    {
      element: <PrimaryLayout />,
      children: [
        {
          path: "/",
          element: <HomePage />,
        },
        {
          path: "signUp",
          element: <SignUp />,
        },
        {
          path: "form1",
          element: <Form1 />,
        },
        {
          path: "form2",
          element: <Form2 />,
        },
        {
          path: "*",
          element: <Navigate to="/" replace={true} />,
        },
      ],
    },
  ]);
};

export default router;
