import { forwardRef, useCallback } from "react";
import { useSnackbar } from "notistack";
import {
  AiOutlineCheckCircle,
  AiOutlineClose,
  AiOutlineExclamationCircle,
  AiOutlineInfoCircle,
  AiOutlineWarning,
} from "react-icons/ai";

// Utility function to generate common structure
const SnackbarBase = forwardRef(
  (
    {
      IconComponent,
      iconColorClass,
      message,
      borderClass,
      bgColorClass,
      textColorClass,
      onClose,
    },
    ref
  ) => (
    <div
      ref={ref}
      className={`flex items-center justify-between max-w-md mx-auto p-3 rounded-md shadow-md ${bgColorClass} ${borderClass} ${textColorClass}`}
      role="alert"
    >
      <div className="flex items-center">
        <IconComponent className={`flex-shrink-0 ${iconColorClass} size-6`} />
        <span className="ml-3 text-lg text-white">{message}</span>
      </div>
      <button onClick={onClose} className={`p-1 rounded-md ${textColorClass}`}>
        <AiOutlineClose className="h-4 w-4" />
      </button>
    </div>
  )
);

const SuccessMessage = forwardRef(({ id, message }, ref) => {
  const { closeSnackbar } = useSnackbar();
  const handleDismiss = useCallback(
    () => closeSnackbar(id),
    [id, closeSnackbar]
  );

  return (
    <SnackbarBase
      ref={ref}
      IconComponent={AiOutlineCheckCircle}
      iconColorClass="text-white"
      borderClass="border-l-4 border-white-400"
      bgColorClass="bg-[#43a047]"
      textColorClass="text-white"
      message={message}
      onClose={handleDismiss}
    />
  );
});

const ErrorMessage = forwardRef(({ id, message }, ref) => {
  const { closeSnackbar } = useSnackbar();
  const handleDismiss = useCallback(
    () => closeSnackbar(id),
    [id, closeSnackbar]
  );

  return (
    <SnackbarBase
      ref={ref}
      IconComponent={AiOutlineExclamationCircle}
      iconColorClass="text-white"
      borderClass="border-l-4 border-white-400/50"
      bgColorClass="bg-[#d32f2f;]"
      textColorClass="text-white"
      message={message}
      onClose={handleDismiss}
    />
  );
});

const WarningMessage = forwardRef(({ id, message }, ref) => {
  const { closeSnackbar } = useSnackbar();
  const handleDismiss = useCallback(
    () => closeSnackbar(id),
    [id, closeSnackbar]
  );

  return (
    <SnackbarBase
      ref={ref}
      IconComponent={AiOutlineWarning}
      iconColorClass="text-white"
      borderClass="border-l-4 border-white-400/50"
      bgColorClass="bg-[#ff9800]"
      textColorClass="text-white"
      message={message}
      onClose={handleDismiss}
    />
  );
});

const InfoMessage = forwardRef(({ id, message }, ref) => {
  const { closeSnackbar } = useSnackbar();
  const handleDismiss = useCallback(
    () => closeSnackbar(id),
    [id, closeSnackbar]
  );

  return (
    <SnackbarBase
      ref={ref}
      IconComponent={AiOutlineInfoCircle}
      iconColorClass="text-white"
      borderClass="border-l-4 border-white-400/50"
      bgColorClass="bg-[#2196f3]"
      textColorClass="text-white"
      message={message}
      onClose={handleDismiss}
    />
  );
});

export { SuccessMessage, ErrorMessage, WarningMessage, InfoMessage };
