import XIcon from "../Assets/Icons/xIcon.svg";
import TelegramIcon from "../Assets/Icons/telegramIcon.svg";
import InstagramIcon from "../Assets/Icons/instagramIcon.svg";
import CoinMarketIcon from "../Assets/Icons/coinMarketIcon.svg";
import UniSwapIcon from "../Assets/Icons/uniSwapIcon.svg";

export default function Footer() {
  return (
    <div className="w-[100%] py-6 bg-[#5c5b5b] flex items-center flex-col gap-3 mt-20">
      <div className="flex items-center gap-5">
        <a href="https://twitter.com/TheJuleToken" target="_blank">
          <img className="w-[2.5rem]" src={XIcon} alt="XIcon" />
        </a>
        <a href="https://t.me/+zXMHEMbcrsVmN2Zh" target="_blank">
          <img className="w-[2.5rem]" src={TelegramIcon} alt="TelegramIcon" />
        </a>
        <a href="https://www.instagram.com/juletoken/" target="_blank">
          <img className="w-[2.5rem]" src={InstagramIcon} alt="InstagramIcon" />
        </a>
        <img className="w-[2.5rem]" src={CoinMarketIcon} alt="CoinMarketIcon" />
        <img className="w-[2.5rem]" src={UniSwapIcon} alt="UniSwapIcon" />
      </div>
      <p className="text-[1rem] font-medium text-white">
        We copyright nothing-all our stuff belongs to you
      </p>
    </div>
  );
}
