import React, { useEffect } from "react";
import { IoAlertCircleSharp } from "react-icons/io5";

const UpdateModal = ({ isOpen, onClose, handleSaveAndExit }) => {
  //   useEffect(() => {
  //     // Function to call on clicking outside the modal content
  //     const handleOutsideClick = (event) => {
  //       if (event.target.id === "modal-backdrop") {
  //         onClose();
  //       }
  //     };

  //     // Add event listener
  //     if (isOpen) {
  //       window.addEventListener("click", handleOutsideClick);
  //     }

  //     // Remove event listener on cleanup
  //     return () => window.removeEventListener("click", handleOutsideClick);
  //   }, [isOpen, onClose]); // Ensure this effect runs only when `isOpen` or `onClose` changes

  if (!isOpen) return null;

  return (
    <div
      id="modal-backdrop"
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center z-10"
    >
      <div className="bg-white p-6 rounded-md shadow-xl flex flex-col gap-8">
        <div className="flex items-center space-x-2 mb-4">
          <IoAlertCircleSharp className="w-12 h-12 text-[#6163f1]" />
          <h2 className="text-lg font-semibold text-gray-700">
            Are you sure you want to join our white list and not participate in
            the contest?
          </h2>
        </div>
        {/* <p className="mb-6 text-sm text-gray-600">
          Are you sure you want to save and exit with contest ?
        </p> */}
        <div className="flex justify-end gap-6">
          <button
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-[#6163f1] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
            onClick={() => {
              handleSaveAndExit();
              onClose();
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateModal;
