import { useEffect, useState } from "react";
import Logo from "../Assets/Images/logo.png";
import Footer from "../Components/Footer";
import { useFormik } from "formik";
import { useStep3Mutation } from "../features/stepsApiSlice";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../features/snackbar/snackbarAction";
import * as Yup from "yup";
import UpdateModal from "../Components/UpdateModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Country, State, City } from "country-state-city";
import Header from "../Components/Header";
import ReactSelect from "react-select";
import Loader from "../Components/Loader/Loader";
import JuleTokenRedirect from "../Components/JuleTokenRedirect";

const step3validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .required("Full name is required")
    .min(2, "Full name must be at least 2 characters long"),

  country: Yup.object().required("Country is required"),

  streetAddress: Yup.string().required("Street address is required"),

  // city: Yup.object().required("City is required"),
  // .matches(/^[a-zA-Z\s]+$/, "City must contain only letters and spaces"),

  // stateProvince: Yup.object().required("State/Province is required"),

  // postalCode: Yup.string()
  //   .required("Postal code is required")
  //   .matches(
  //     /^\d{6}(-\d{4})?$/,
  //     "Postal code must be a valid 5-digit or 9-digit (ZIP+4)"
  //   ),

  postalCode: Yup.string()
    .required("Postal code is required")
    .matches(/^\d{4,10}$/, "Postal code must be between 4 and 10 digits long"),
});

export default function Form2() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [popUpModalOpen, setPopUpModalOpen] = useState(false);
  const id = searchParams.get("id");

  const [step3, { isLoading: step3IsLoading }] = useStep3Mutation();

  const step3Formik = useFormik({
    initialValues: {
      id: id,
      fullName: "",
      country: "",
      streetAddress: "",
      city: "",
      stateProvince: "",
      postalCode: "",
    },
    enableReinitialize: true,
    validationSchema: step3validationSchema,

    onSubmit: (values) => {
      const data = {
        id: values?.id,
        fullName: values?.fullName,
        country: values?.country?.name,
        streetAddress: values?.streetAddress,
        city: values?.city?.name,
        stateProvince: values?.stateProvince?.name,
        postalCode: values?.postalCode,
      };
      step3(data)
        .unwrap()
        .then((res) => {
          dispatch(
            showSuccess({
              message: "Submitted Successfully",
            })
          );
          navigate("/");
        })
        .catch((e) => {
          dispatch(
            showError({
              message:
                e?.message?.details[0]?.message ?? "Something went wrong",
            })
          );
        });
    },
  });

  const handleStartOver = () => {
    navigate("/");
  };

  const handleSaveAndExit = () => {
    dispatch(
      showSuccess({
        message: "Saved without contest ",
      })
    );
    navigate("/");
  };

  const handleOpenPopUp = () => {
    setPopUpModalOpen(true);
  };

  useEffect(() => {
    if (!id) navigate("/");
  }, [id, navigate]);

  // console.log("Country", step3Formik?.values?.country);

  return (
    <div>
      {step3IsLoading ? (
        <div className="h-screen w-full flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <>
          <JuleTokenRedirect />
          <UpdateModal
            isOpen={popUpModalOpen}
            onClose={() => setPopUpModalOpen(false)}
            handleSaveAndExit={handleSaveAndExit}
          />
          <div className="flex  justify-center min-h-[100vh]">
            <div className="w-[40%] max-[1380px]:w-[50%] max-[1140px]:w-[60%] max-[800px]:w-[80%] max-[600px]:w-[85%]">
              <Header activeStep={3} />
              <div className="mt-16">
                <form noValidate onSubmit={step3Formik.handleSubmit}>
                  <div className="flex flex-col gap-7">
                    <img className="w-[7rem]" src={Logo} alt="Logo" />
                    <h1 className="text-[2.5rem] uppercase font-extrabold text-[#6163f1] leading-[2.8rem]">
                      Contest
                    </h1>
                    <p className="text-[1.1rem] text-black font-medium">
                      After the presale, five random participants will win
                      unique Jule-inspired NFT art. We'll also create new art
                      for each CEX listing, gifting it to a random presale
                      member. Winners receive a free NFT frame from our partner
                      Tokenframe. Share your shipping address to enter the
                      contest;{" "}
                      <span className="underline">
                        entering the contest is optional.
                      </span>
                    </p>
                    <div className="flex flex-col gap-3">
                      <label className="text-[1.1rem] font-medium text-black">
                        Full Name:
                      </label>
                      <input
                        name="fullName"
                        onChange={step3Formik?.handleChange}
                        onBlur={step3Formik?.handleBlur}
                        value={step3Formik?.values?.fullName}
                        className="w-[60%] py-3 bg-[#f3f3f3] placeholder:text-black placeholder:font-medium pl-4 rounded-lg outline-none  max-[900px]:w-[75%] max-[600px]:w-full"
                        type="text"
                        placeholder="Enter Here"
                      />
                      <div>
                        {step3Formik?.touched?.fullName &&
                          step3Formik?.errors?.fullName && (
                            <span className="text-red-400 px-4 py-2 text-xs">
                              {step3Formik?.errors?.fullName}
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="flex flex-col gap-3">
                      <label className="text-[1.1rem] font-medium text-black">
                        Country:
                      </label>
                      <ReactSelect
                        name="country"
                        onChange={(item) => {
                          step3Formik.setFieldValue("country", item);
                          step3Formik.setFieldValue("stateProvince", "");
                          step3Formik.setFieldValue("city", "");
                        }}
                        onBlur={step3Formik?.handleBlur}
                        value={step3Formik?.values?.country}
                        options={Country.getAllCountries()}
                        getOptionLabel={(options) => {
                          return options["name"];
                        }}
                        getOptionValue={(options) => {
                          return options["name"];
                        }}
                      />
                      <div>
                        {step3Formik?.touched?.country &&
                          step3Formik?.errors?.country && (
                            <span className="text-red-400 px-4 py-2 text-xs">
                              {step3Formik?.errors?.country}
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="flex flex-col gap-3">
                      <label className="text-[1.1rem] font-medium text-black">
                        State/Province:
                      </label>
                      <ReactSelect
                        name="stateProvince"
                        onChange={(item) => {
                          step3Formik.setFieldValue("stateProvince", item);
                          step3Formik.setFieldValue("city", "");
                        }}
                        onBlur={step3Formik?.handleBlur}
                        value={step3Formik?.values?.stateProvince}
                        options={State?.getStatesOfCountry(
                          step3Formik?.values?.country?.isoCode
                        )}
                        getOptionLabel={(options) => {
                          return options["name"];
                        }}
                        getOptionValue={(options) => {
                          return options["name"];
                        }}
                      />
                      <div>
                        {step3Formik?.touched?.stateProvince &&
                          step3Formik?.errors?.stateProvince && (
                            <span className="text-red-400 px-4 py-2 text-xs">
                              {step3Formik?.errors?.stateProvince}
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="flex flex-col gap-3">
                      <label className="text-[1.1rem] font-medium text-black">
                        City:
                      </label>
                      <ReactSelect
                        name="city"
                        onChange={(item) => {
                          step3Formik.setFieldValue("city", item);
                        }}
                        onBlur={step3Formik?.handleBlur}
                        value={step3Formik?.values?.city}
                        options={City.getCitiesOfState(
                          step3Formik?.values?.stateProvince?.countryCode,
                          step3Formik?.values?.stateProvince?.isoCode
                        )}
                        getOptionLabel={(options) => {
                          return options["name"];
                        }}
                        getOptionValue={(options) => {
                          return options["name"];
                        }}
                      />
                      <div>
                        {step3Formik?.touched?.city &&
                          step3Formik?.errors?.city && (
                            <span className="text-red-400 px-4 py-2 text-xs">
                              {step3Formik?.errors?.city}
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="flex flex-col gap-3">
                      <label className="text-[1.1rem] font-medium text-black">
                        Street Address:
                      </label>
                      <input
                        name="streetAddress"
                        onChange={step3Formik?.handleChange}
                        onBlur={step3Formik?.handleBlur}
                        value={step3Formik?.values?.streetAddress}
                        className="w-[60%] py-3 bg-[#f3f3f3] placeholder:text-black placeholder:font-medium pl-4 rounded-lg outline-none  max-[900px]:w-[75%] max-[600px]:w-full"
                        type="text"
                        placeholder="Enter Here"
                      />
                      <div>
                        {step3Formik?.touched?.streetAddress &&
                          step3Formik?.errors?.streetAddress && (
                            <span className="text-red-400 px-4 py-2 text-xs">
                              {step3Formik?.errors?.streetAddress}
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="flex flex-col gap-3">
                      <label className="text-[1.1rem] font-medium text-black">
                        Postal Code:
                      </label>
                      <input
                        name="postalCode"
                        onChange={step3Formik?.handleChange}
                        onBlur={step3Formik?.handleBlur}
                        value={step3Formik?.values?.postalCode}
                        className="w-[60%] py-3 bg-[#f3f3f3] placeholder:text-black placeholder:font-medium pl-4 rounded-lg outline-none  max-[900px]:w-[75%] max-[600px]:w-full"
                        type="text"
                        placeholder="Enter Here"
                      />
                      <div>
                        {step3Formik?.touched?.postalCode &&
                          step3Formik?.errors?.postalCode && (
                            <span className="text-red-400 px-4 py-2 text-xs">
                              {step3Formik?.errors?.postalCode}
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="flex flex-col gap-3">
                      <div className="flex items-center gap-14 max-[450px]:flex-col max-[450px]:gap-5">
                        <button
                          type="submit"
                          className="w-full py-3 text-white bg-[#6163f1] font-bold rounded-2xl text-[1.2rem]"
                        >
                          Save & Enter Contest
                        </button>
                        <button
                          type="button"
                          className="w-full py-3 text-[#6163f1] border-[#6163f1] font-bold rounded-2xl text-[1.2rem] border-2"
                          onClick={handleOpenPopUp}
                        >
                          Save & Skip Contest
                        </button>
                      </div>
                      <button
                        onClick={handleStartOver}
                        className="w-full py-3 text-[#a1a1a1] border-2 border-[#a1a1a1] font-bold rounded-2xl text-[1.2rem] max-[600px]:w-full"
                      >
                        Start Over
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </div>
  );
}
