import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  ErrorMessage,
  InfoMessage,
  SuccessMessage,
  WarningMessage,
} from "./Components/Snackbar";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { SnackbarUtilsConfigurator } from "./features/snackbar/useSnackbar";
import store from "./app/store";
import { Web3OnboardProvider } from "@web3-onboard/react";
import web3Onboard from "./setupOnboard";
import { SNACKBAR_DURATION } from "./utils/defaults";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <SnackbarProvider
        autoHideDuration={SNACKBAR_DURATION}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        Components={{
          success: SuccessMessage,
          error: ErrorMessage,
          warning: WarningMessage,
          info: InfoMessage,
        }}
        maxSnack={3}
      >
        <SnackbarUtilsConfigurator />
        <Web3OnboardProvider web3Onboard={web3Onboard}>
          <App />
        </Web3OnboardProvider>
      </SnackbarProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
