import apiSlice from "../app/api/apiSlice";

export const stepsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // inviteStatus: builder.query({
    //   query: (queries) => {
    //     let queryString = "";
    //     for (const key in queries) {
    //       if (queries[key]) {
    //         queryString = `${queryString}${queryString ? "&" : "?"}${key}=${
    //           queries[key]
    //         }`;
    //       }
    //     }
    //     return {
    //       url: `app/v1/organization/invite/find-all${queryString}`,
    //     };
    //   },
    //   providesTags: ["Dashboard"],
    // }),

    getEnquiryId2: builder.query({
      query: () => ({
        url: `/v1/auth/account/enquiry`,
      }),
      providesTags: ["EnquiryId"],
    }),

    getEnquiryId: builder.query({
      query: () => ({
        url: `/v1/auth/account/enquiry`,
      }),
      providesTags: ["EnquiryId"],
    }),

    twitterAuth: builder.query({
      query: () => ({
        url: `http://34.234.23.27/v1/auth/oauth/twitter`,
      }),
      providesTags: ["EnquiryId"],
    }),

    // getEnquiryId: builder.query({
    //   query: (queries) => {
    //     return {
    //       url: "/parameters/priceMaster",
    //       params: omitNullishKeys(queries),
    //     };
    //   },
    //   // transformResponse: (res) => res.data,
    //   providesTags: ["EnquiryId"],
    // }),

    step2: builder.mutation({
      query: (step2Data) => ({
        url: "/v1/user/basic",
        method: "POST",
        body: step2Data,
      }),
      // invalidatesTags: ["Organizations"],
    }),

    step3: builder.mutation({
      query: (step3Data) => ({
        url: "/v1/user/contestAddress",
        method: "POST",
        body: step3Data,
      }),
      // invalidatesTags: ["Organizations"],
    }),

    walletDetails: builder.mutation({
      query: (walletData) => ({
        url: "/v1/auth/wallets/create",
        method: "POST",
        body: walletData,
      }),
      // invalidatesTags: ["Organizations"],
    }),

    createUser: builder.mutation({
      query: (userData) => ({
        url: "/v1/user/create",
        method: "POST",
        body: userData,
      }),
      // invalidatesTags: ["Organizations"],
    }),
  }),
});

export const {
  useStep2Mutation,
  useStep3Mutation,
  useGetEnquiryId2Query,
  useLazyGetEnquiryIdQuery,
  useCreateUserMutation,
  useWalletDetailsMutation,
} = stepsApiSlice;
