import { init } from "@web3-onboard/react";
import injectedModule from "@web3-onboard/injected-wallets";
import infinityWalletModule from "@web3-onboard/infinity-wallet";
import dcentModule from "@web3-onboard/dcent";
import safeModule from "@web3-onboard/gnosis";
import keystoneModule from "@web3-onboard/keystone";
import coinbaseModule from "@web3-onboard/coinbase";
import tahoModule from "@web3-onboard/taho";
import trustModule from "@web3-onboard/trust";
import frontierModule from "@web3-onboard/frontier";
import metamaskSDK from "@web3-onboard/metamask";
import metamaskModule from "@web3-onboard/metamask";

const INFURA_KEY = "";

const injected = injectedModule();
const coinbase = coinbaseModule();
const dcent = dcentModule();
const infinityWallet = infinityWalletModule();
const keystone = keystoneModule();
const safe = safeModule();
const taho = tahoModule(); 
const trust = trustModule();
const frontier = frontierModule();
const metamask = metamaskModule({
  options: {
    extensionOnly: false,
    i18nOptions: {
      enabled: true,
    },
    dappMetadata: {
      name: "Web3Onboard React Demo",
    },
  },
});
const metamaskSDKWallet = metamaskSDK({
  options: {
    extensionOnly: false,
    i18nOptions: {
      enabled: true,
    },
    dappMetadata: {
      name: "Demo Web3Onboard",
    },
  },
});

// const magic = magicModule({
//   apiKey: "apiKey",
// });

const wallets = [
  infinityWallet,
  injected,
  trust,
  frontier,
  taho,
  coinbase,
  dcent,
  safe,
  keystone,
  metamaskSDKWallet,
  metamask,
];

const chains = [
  {
    id: "0x1",
    token: "ETH",
    label: "Ethereum Mainnet",
    rpcUrl: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
  },
  {
    id: 11155111,
    token: "ETH",
    label: "Sepolia",
    rpcUrl: "https://rpc.sepolia.org/",
  },
  {
    id: "0x13881",
    token: "MATIC",
    label: "Polygon - Mumbai",
    rpcUrl: "https://matic-mumbai.chainstacklabs.com",
  },
  {
    id: "0x38",
    token: "BNB",
    label: "Binance",
    rpcUrl: "https://bsc-dataseed.binance.org/",
  },
  {
    id: "0xA",
    token: "OETH",
    label: "OP Mainnet",
    rpcUrl: "https://mainnet.optimism.io",
  },
  {
    id: "0xA4B1",
    token: "ARB-ETH",
    label: "Arbitrum",
    rpcUrl: "https://rpc.ankr.com/arbitrum",
  },
  {
    id: "0xa4ec",
    token: "ETH",
    label: "Celo",
    rpcUrl: "https://1rpc.io/celo",
  },
  {
    id: 666666666,
    token: "DEGEN",
    label: "Degen",
    rpcUrl: "https://rpc.degen.tips",
  },
];

const appMetadata = {
  name: "Connect Wallet Example",
  icon: "<svg>My App Icon</svg>",
  description: "Example showcasing how to connect a wallet.",
  recommendedInjectedWallets: [
    { name: "MetaMask", url: "https://metamask.io" },
    { name: "Coinbase", url: "https://wallet.coinbase.com/" },
  ],
};

const web3Onboard = init({
  wallets,
  chains,
  appMetadata,
});

export default web3Onboard;
