import Logo from "../Assets/Images/logo.png";
import Footer from "../Components/Footer";
import { useFormik } from "formik";
import { useStep2Mutation } from "../features/stepsApiSlice";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../features/snackbar/snackbarAction";
import * as Yup from "yup";
import Header from "../Components/Header";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useEffect } from "react";
import Loader from "../Components/Loader/Loader";
import JuleTokenRedirect from "../Components/JuleTokenRedirect";

const step2validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),

  contestBidAmount: Yup.number()
    .typeError("Contest bid amount must be a number")
    .positive("Contest bid amount must be positive")
    .required("Contest bid amount is required"),

  twitterUserName: Yup.string()
    .matches(
      /^@\w+$/,
      'Twitter username must start with "@" followed by characters'
    )
    .required("Twitter username is required"),
});

export default function Form1() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [step2, { isLoading: step2IsLoading }] = useStep2Mutation();

  const step2Formik = useFormik({
    initialValues: {
      id: id,
      email: "",
      contestBidAmount: null,
      twitterUserName: "",
    },
    enableReinitialize: true,
    validationSchema: step2validationSchema,

    onSubmit: (values) => {
      step2(values)
        .unwrap()
        .then((res) => {
          dispatch(
            showSuccess({
              message: "Submitted Successfully",
            })
          );
          navigate({
            pathname: "/form2",
            search: `?${createSearchParams({
              id: res?.data?.id,
            })}`,
          });
        })
        .catch((e) => {
          dispatch(
            showError({
              message: "Something went wrong",
            })
          );
        });
    },
  });

  const handleStartOver = () => {
    navigate("/");
  };

  useEffect(() => {
    if (!id) navigate("/");
  }, [id, navigate]);

  // console.log("form1Id", id);

  return (
    <div>
      {step2IsLoading ? (
        <div className="h-screen w-full flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <>
          <JuleTokenRedirect />
          <div className="flex  justify-center min-h-[100vh]">
            <div className="w-[40%] max-[1380px]:w-[50%] max-[1140px]:w-[60%] max-[800px]:w-[80%] max-[600px]:w-[85%]">
              <Header activeStep={2} />
              <div className="mt-16">
                <form noValidate onSubmit={step2Formik.handleSubmit}>
                  <div className="flex flex-col gap-7">
                    <img className="w-[7rem]" src={Logo} alt="Logo" />
                    <h1 className="text-[2.5rem] uppercase font-extrabold text-[#6163f1] leading-[2.8rem]">
                      apply
                    </h1>
                    <div className="flex flex-col gap-4">
                      <p className="text-[1.1rem] font-medium text-black">
                        1.) What is your email?
                      </p>
                      <p className="text-[0.90rem] text-[#5c5b5b] text-medium">
                        (We request this to alert you when the presale goes
                        live)
                      </p>
                      <input
                        name="email"
                        onChange={step2Formik?.handleChange}
                        onBlur={step2Formik?.handleBlur}
                        value={step2Formik?.values?.email}
                        className="w-[60%] py-3 bg-[#f3f3f3] placeholder:text-black placeholder:font-medium pl-4 rounded-lg outline-none max-[900px]:w-[75%] max-[600px]:w-full"
                        type="email"
                        placeholder="Email"
                      />
                      <div>
                        {step2Formik?.touched?.email &&
                          step2Formik?.errors?.email && (
                            <span className="text-red-400 px-4 py-2 text-xs">
                              {step2Formik?.errors?.email}
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="flex flex-col gap-4">
                      <p className="text-[1.1rem] font-medium text-black">
                        2.) How Much ETH are you willing to commit?
                      </p>
                      <input
                        name="contestBidAmount"
                        onChange={step2Formik?.handleChange}
                        onBlur={step2Formik?.handleBlur}
                        value={step2Formik?.values?.contestBidAmount}
                        className="w-[60%] py-3 bg-[#f3f3f3] placeholder:text-black placeholder:font-medium pl-4 rounded-lg outline-none  max-[900px]:w-[75%] max-[600px]:w-full"
                        type="number"
                        placeholder="Enter Amount"
                      />
                      <div>
                        {step2Formik?.touched?.contestBidAmount &&
                          step2Formik?.errors?.contestBidAmount && (
                            <span className="text-red-400 px-4 py-2 text-xs">
                              {step2Formik?.errors?.contestBidAmount}
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="flex flex-col gap-4">
                      <p className="text-[1.1rem] font-medium text-black">
                        3.) What is your X(Twitter) username?
                      </p>
                      <input
                        name="twitterUserName"
                        onChange={step2Formik?.handleChange}
                        onBlur={step2Formik?.handleBlur}
                        value={step2Formik?.values?.twitterUserName}
                        className="w-[60%] py-3 bg-[#f3f3f3] placeholder:text-black placeholder:font-medium pl-4 rounded-lg outline-none  max-[900px]:w-[75%] max-[600px]:w-full"
                        type="text"
                        placeholder="@username"
                      />
                      <div>
                        {step2Formik?.touched?.twitterUserName &&
                          step2Formik?.errors?.twitterUserName && (
                            <span className="text-red-400 px-4 py-2 text-xs">
                              {step2Formik?.errors?.twitterUserName}
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-4 mt-14">
                      <button
                        type="submit"
                        className="w-[60%] py-3 text-white bg-[#6163f1] font-bold rounded-2xl text-[1.2rem] max-[600px]:w-full"
                      >
                        Next Step
                      </button>
                      <p className="text-[1.1rem] text-black font-medium">
                        Three steps that take less than a minute
                      </p>
                      <button
                        type="button"
                        className="w-[50%] py-3 text-[#a1a1a1] border-2 border-[#a1a1a1] font-bold rounded-2xl text-[1.2rem] max-[600px]:w-full"
                        onClick={handleStartOver}
                      >
                        Start Over
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </div>
  );
}
