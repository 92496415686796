import { useEffect, useState } from "react";
import Logo from "../Assets/Images/logo.png";
import VideoImg from "../Assets/Images/video.jpeg";
import Footer from "../Components/Footer";
import Modal from "../Components/Modal";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../features/snackbar/snackbarAction";
import { useLazyGetEnquiryIdQuery } from "../features/stepsApiSlice";
import { useNavigate } from "react-router-dom";
import Loader from "../Components/Loader/Loader";
import Header from "../Components/Header";
import JuleTokenRedirect from "../Components/JuleTokenRedirect";

export default function HomePage() {
  const activeStep = 0;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    // This code runs when the component mounts
    sessionStorage.clear();
  }, []); // The empty array ensures this effect runs only once upon mounting

  const [
    getEnquiryId,
    {
      enquiryIdData,
      enquiryIdIsSuccess,
      enquiryIdIsError,
      isLoading: enquiryIdIsLoading,
      isFetching: enquiryIdIsFetching,
    },
  ] = useLazyGetEnquiryIdQuery();

  const handleStartApplication = () => {
    getEnquiryId()
      .unwrap()
      .then((res) => {
        sessionStorage.setItem("enquiryId", JSON.stringify(res?.data?.id));
        navigate("/signUp");
        // dispatch(
        //   showSuccess({
        //     message: "Enquiry Id Generated Successfully",
        //   })
        // );
      })
      .catch((err) => {
        dispatch(
          showError({
            message: "Error generating EnquiryId !",
          })
        );
      });
  };

  return (
    <div>
      {enquiryIdIsLoading || enquiryIdIsFetching ? (
        <div className="h-screen w-full flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <>
          <JuleTokenRedirect />
          <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)} />
          <div className="flex  justify-center min-h-[100vh]">
            <div className="w-[40%] max-[1380px]:w-[50%] max-[1140px]:w-[60%] max-[800px]:w-[80%] max-[600px]:w-[85%]">
              <Header activeStep={0} />
              <div className="mt-16">
                <div className="flex flex-col gap-7">
                  <img className="w-[7rem]" src={Logo} alt="logo" />
                  <h1 className="text-[2.5rem] uppercase font-extrabold text-[#6163f1] leading-[2.8rem]">
                    jule whitelist <br /> application
                  </h1>
                  <p className="text-[1.1rem] text-black font-medium">
                    The revolution starts with you:
                  </p>
                  <ul className="list-disc flex flex-col gap-1 pl-5">
                    <li className="text-[1.1rem] text-black font-medium">
                      Limited Supply of 100 million tokens
                    </li>
                    <li className="text-[1.1rem] text-black font-medium">
                      60% of the raise is locked in a liquidity pool for three
                      months, relock if needed
                    </li>
                    <li className="text-[1.1rem] text-black font-medium">
                      ERC20 Token
                    </li>
                    <li className="text-[1.1rem] text-black font-medium">
                      Presale rate of 30% less than DEX price
                    </li>
                    <li className="text-[1.1rem] text-black font-medium">
                      Unique NFT Art Giveaways & Airdrops
                    </li>
                    <li className="text-[1.1rem] text-black font-medium">
                      Project guided by seasoned entrepreneurs who are tired of
                      shitcoins and centralized authorities.{" "}
                    </li>
                  </ul>
                  <div className="flex items-center justify-center">
                    <img
                      onClick={() => setModalOpen(true)}
                      className="w-[60%] max-[600px]:w-full"
                      src={VideoImg}
                      alt="video"
                    />
                  </div>
                  <div className="flex flex-col items-center justify-center gap-4 mt-14">
                    <button
                      onClick={handleStartApplication}
                      className="w-[60%] py-3 text-white bg-[#6163f1] font-bold rounded-2xl text-[1.2rem] max-[600px]:w-full"
                    >
                      Get Started
                    </button>
                    <p className="text-[1.1rem] text-black font-medium">
                      Three steps that take less than a minute
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </div>
  );
}
