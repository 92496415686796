import { useEffect, useState } from "react";
import Logo from "../Assets/Images/logo.png";
import Footer from "../Components/Footer";
import WalletIcon from "../Assets/Icons/walletIcon.svg";
import XIcon from "../Assets/Icons/xIcon.svg";
import TelegramIcon from "../Assets/Icons/telegramIcon.svg";
import CrossIcon from "../Assets/Icons/crossIcon.svg";
import OptionalIcon from "../Assets/Icons/optionalIcon.svg";
import {
  useCreateUserMutation,
  useWalletDetailsMutation,
} from "../features/stepsApiSlice";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../features/snackbar/snackbarAction";
import { FaCheckCircle } from "react-icons/fa";
import { useConnectWallet } from "@web3-onboard/react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Header from "../Components/Header";
import Loader from "../Components/Loader/Loader";
import JuleTokenRedirect from "../Components/JuleTokenRedirect";

export default function SignUp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const enquiryId = JSON.parse(sessionStorage.getItem("enquiryId"));
  const [isWallet, setIsWallet] = useState(false);
  const [walletId, setWalletId] = useState(
    JSON.parse(sessionStorage.getItem("walletId"))
  );
  const [searchParams, setSearchParams] = useSearchParams();
  // const twitterId = searchParams.get("twitter");

  const handleWalletConnection = async () => {
    if (!wallet && !walletId) {
      await connect();
    } else {
      if (wallet) {
        await disconnect(wallet);
        sessionStorage.removeItem("walletId");
        setWalletId("");
      }
      if (!wallet && walletId) {
        sessionStorage.removeItem("walletId");
        setWalletId("");
      }
    }
  };

  const [walletDetails, { isLoading: walletDetailsIsLoading }] =
    useWalletDetailsMutation();

  const [createUser, { isLoading: userIsLoading }] = useCreateUserMutation();

  // const handleNextStepAfterSignUp = () => {
  //   if (!isWallet && walletId) {
  //     createUser({
  //       enquiryId,
  //       metaMaskTempId: walletId,
  //       // twitterTempId: twitterId,
  //     })
  //       .unwrap()
  //       .then((res) => {
  //         dispatch(
  //           showSuccess({
  //             message: res?.message ?? "User Created Successfully",
  //           })
  //         );
  //         navigate({
  //           pathname: "/form1",
  //           search: `?${createSearchParams({
  //             id: res?.data?.id,
  //           })}`,
  //         });
  //       })
  //       .catch((err) => {
  //         dispatch(
  //           showError({
  //             message: "Something went wrong !",
  //           })
  //         );
  //       });
  //   } else if (!walletId) {
  //     dispatch(
  //       showError({
  //         message: "Please sign in with your wallet to continue",
  //       })
  //     );
  //   } else if (isWallet) {
  //     dispatch(
  //       showError({
  //         message: "This wallet is already on our whitelist.",
  //       })
  //     );
  //   }
  // };

  const handleNextStepAfterSignUp = () => {
    // Helper function to show error messages
    const showErrorDispatch = (message) => {
      dispatch(showError({ message }));
    };

    if (walletId) {
      if (isWallet) {
        showErrorDispatch("This wallet is already on our whitelist.");
      } else {
        createUser({
          enquiryId,
          metaMaskTempId: walletId,
          // twitterTempId: twitterId,
        })
          .unwrap()
          .then((res) => {
            dispatch(
              showSuccess({
                message: res?.message ?? "User Created Successfully",
              })
            );
            navigate({
              pathname: "/form1",
              search: `?id=${res?.data?.id}`,
            });
          })
          .catch((err) => {
            showErrorDispatch("Something went wrong!");
          });
      }
    } else {
      showErrorDispatch("Please sign in with your wallet to continue");
    }
  };

  const handleStartOver = () => {
    navigate("/");
  };

  useEffect(() => {
    if (wallet && wallet?.accounts[0]?.balance) {
      walletDetails({
        accounts: wallet?.accounts,
        label: wallet?.label,
      })
        .unwrap()
        .then((res) => {
          dispatch(
            showSuccess({
              message: res?.message ?? "Wallet connected successfully!",
            })
          );
          sessionStorage.setItem("walletId", JSON.stringify(res?.data?.id));
          setWalletId(res?.data?.id);
          setIsWallet(res?.data?.isWallet);
        })
        .catch((err) => {
          dispatch(
            showError({
              message: "Wallet connecion failed!",
            })
          );
        });
    }
  }, [wallet, wallet?.accounts[0]?.balance, dispatch]);

  const handleSignUpWithTwitter = () => {
    window.location.href =
      "https://whitelist.juletoken.com/v1/auth/oauth/twitter";
    // "http://localhost:3004/v1/auth/oauth/twitter";
  };

  const handleRedirectToTelegram = () => {
    // console.log("clicked");
    window.open(
      "https://t.me/+zXMHEMbcrsVmN2Zh",
      "_blank",
      "noopener,noreferrer"
    );
  };

  useEffect(() => {
    if (!enquiryId) navigate("/");
  }, [enquiryId, navigate]);

  // console.log("walletDetails", wallet, wallet?.accounts[0]?.balance);
  // console.log("walletBalance", wallet?.accounts[0]?.balance);
  // console.log("enquiryId", enquiryId);
  // console.log("twitterId", twitterId);
  // console.log("walletId", walletId);
  // console.log("walletDetailsIsLoading", walletDetailsIsLoading);
  // console.log("isWallet", isWallet);

  return (
    <div>
      {walletDetailsIsLoading || userIsLoading ? (
        <div className="h-screen w-full flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <>
          <JuleTokenRedirect />
          <div className="flex  justify-center min-h-[100vh]">
            <div className="w-[40%] max-[1380px]:w-[50%] max-[1140px]:w-[60%] max-[800px]:w-[80%] max-[600px]:w-[85%]">
              <Header activeStep={1} />
              <div className="mt-16">
                {/* jule white list application */}
                <div className="flex flex-col gap-7">
                  <img className="w-[7rem]" src={Logo} alt="logo" />
                  <h1 className="text-[2.5rem] uppercase font-extrabold text-[#6163f1] leading-[2.8rem]">
                    connect
                  </h1>
                  <p className="text-[1.1rem] font-bold text-black">
                    Connect your Wallet, X(Twitter) and Telegram to continue!
                  </p>
                  <div className="flex flex-col gap-5">
                    <div className="flex items-center gap-16 max-[450px]:flex-col max-[450px]:gap-2 max-[450px]:items-start">
                      <button
                        type="button"
                        className="w-[45%] py-3 text-[1.1rem] text-white bg-[#545353] flex items-center justify-center gap-2 rounded-2xl max-[450px]:w-full"
                        // onClick={signInWithEthereum}
                        onClick={handleWalletConnection}
                      >
                        <span>
                          <img
                            className="w-[2rem] mt-2"
                            src={WalletIcon}
                            alt="WalletIcon"
                          />
                        </span>
                        {walletId || wallet
                          ? "Disconnect Wallet"
                          : "Connect Wallet"}
                      </button>
                      <p className="text-[1.1rem] text-black font-medium flex items-center">
                        {walletId || wallet ? (
                          <FaCheckCircle className="mr-2 text-green-600 w-5 h-5" />
                        ) : (
                          <span>
                            <img
                              className="w-[1.8rem] mt-2 pl-0"
                              src={CrossIcon}
                              alt="CrossIcon"
                            />
                          </span>
                        )}
                        {walletId || wallet ? "Signed In" : "Sign In"}
                      </p>
                    </div>
                    {/* <div className="flex items-center gap-16 max-[450px]:flex-col max-[450px]:gap-2 max-[450px]:items-start">
                      <button
                        className="w-[45%] py-3 text-[1.1rem] text-white bg-[#62b9fd] flex items-center justify-center gap-2 rounded-2xl max-[450px]:w-full"
                        onClick={handleSignUpWithTwitter}
                      >
                        <span>
                          <img
                            className="w-[2rem] mt-2"
                            src={XIcon}
                            alt="XIcon"
                          />
                        </span>
                        Sign in with X(Twitter)
                      </button>
                      <p className="text-[1.1rem] text-black font-medium flex items-center">
                        {twitterId ? (
                          <FaCheckCircle className="mr-2 text-green-600 w-5 h-5" />
                        ) : (
                          <span>
                            <img
                              className="w-[1.8rem] mt-2 pl-0"
                              src={CrossIcon}
                              alt="CrossIcon"
                            />
                          </span>
                        )}
                        {twitterId ? "Signed In" : "Sign In"}
                      </p>
                    </div> */}
                    <div className="flex items-center gap-16 max-[450px]:flex-col max-[450px]:gap-2 max-[450px]:items-start">
                      <button
                        className="w-[45%] py-3 text-[1.1rem] text-white bg-[#1b70b3] flex items-center justify-center gap-2 rounded-2xl max-[450px]:w-full"
                        onClick={handleRedirectToTelegram}
                      >
                        <span>
                          <img
                            className="w-[2rem] mt-2"
                            src={TelegramIcon}
                            alt="TelegramIcon"
                          />
                        </span>
                        Join our Telegram
                      </button>
                      <p className="text-[1.1rem] text-black font-medium flex items-center">
                        <span>
                          <img
                            className="w-[1.8rem] mt-2"
                            src={OptionalIcon}
                            alt="OptionalIcon"
                          />
                        </span>
                        Optional
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center gap-4 mt-14">
                    <button
                      onClick={handleNextStepAfterSignUp}
                      className="w-[60%] py-3 text-white bg-[#6163f1] font-bold rounded-2xl text-[1.2rem] max-[600px]:w-full"
                    >
                      Next Step
                    </button>
                    <p className="text-[1.1rem] text-black font-medium">
                      Three steps that take less than a minute
                    </p>
                    <button
                      onClick={handleStartOver}
                      className="w-[50%] py-3 text-[#a1a1a1] border-2 border-[#a1a1a1] font-bold rounded-2xl text-[1.2rem] max-[600px]:w-full"
                    >
                      Start Over
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </div>
  );
}
