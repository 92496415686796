import { RouterProvider } from "react-router-dom";
import router from "./routes.js";

function App() {
  return (
    <div>
      <RouterProvider router={router()} />
    </div>
  );
}

export default App;
